<template>
  <div id="report-periodic">
    <a-row :gutter="[16, 16]" class="mt-3">
      <a-col :xs="24" :xl="8">
        <h3>Student NIS</h3>
        <p class="text-dark">{{ reportData.nis }}</p>
      </a-col>
      <a-col :xs="24" :xl="8">
        <h3>Student Name</h3>
        <p class="text-dark">{{ reportData.name }}</p>
      </a-col>
      <a-col :xs="24" :xl="8">
        <h3>Reporting Date</h3>
        <p class="text-dark">{{ reportData.reporting_date }}</p>
      </a-col>
    </a-row>
    <hr />
    <div
      v-if="
        reportType === 'status' &&
        (reportData.list_assignments.length ||
          reportData.list_daily_exams.length)
      "
      class="mt-4"
    >
      <h3>Status Type</h3>
      <div class="d-flex align-items-center my-2">
        <a-icon
          class="mr-3 text-success"
          type="check-circle"
          style="font-size: 20px"
        />
        Above the Passing Grade (KKM)
      </div>
      <div class="d-flex align-items-center mb-2">
        <a-icon
          class="mr-3 text-danger"
          type="check-circle"
          style="font-size: 20px"
        />
        Bellow the Passing Grade (KKM)
      </div>
      <div class="d-flex align-items-center mb-2">
        <a-icon
          class="mr-3 text-warning"
          type="exclamation-circle"
          style="font-size: 20px"
        />
        Not graded yet
      </div>
      <div class="d-flex align-items-center">
        <a-icon class="mr-3" type="minus-circle" style="font-size: 20px" />
        Not submitted yet
      </div>
    </div>
    <div v-for="loop in itemLoops" :key="loop">
      <div class="d-flex align-items-center mt-5">
        <h3>
          {{
            loop === "list_assignments"
              ? "Assignments"
              : loop === "list_daily_exams"
              ? "Daily Exams (UH)"
              : ""
          }}
        </h3>
        <h3 v-if="reportType === 'score'" class="ml-auto">
          Average Score:
          {{
            loop === "list_assignments"
              ? reportData.average_assignment_score || "-"
              : loop === "list_daily_exams"
              ? reportData.average_daily_exam_score || "-"
              : "-"
          }}
        </h3>
      </div>
      <template v-if="!reportData[loop].length">
        <div class="mt-2">No Data</div>
      </template>
      <template v-else>
        <div v-for="item in reportData[loop]" :key="item.date" class="mt-2">
          <p class="text-dark">
            {{ item.date }} | {{ item.lists.length }}
            {{
              loop === "list_assignments"
                ? "Assignment"
                : loop === "list_daily_exams"
                ? "Daily Exam"
                : ""
            }}(s)
          </p>
          <table class="table table-bordered table-responsive-sm mt-2">
            <thead>
              <tr>
                <th class="text-center">No.</th>
                <th>Subject</th>
                <th>Type</th>
                <th class="text-center">
                  {{ reportType === "status" ? "Status" : "Score" }}
                </th>
              </tr>
            </thead>
            <tr v-for="(list, i) in item.lists" :key="list.id">
              <td class="text-center">{{ i + 1 }}.</td>
              <td width="50%">{{ list.subject }}</td>
              <td width="30%">{{ list.type }}</td>
              <td width="10%" class="text-center">
                <template v-if="reportType === 'status'">
                  <a-icon
                    :type="statusIcon(list.status).icon"
                    :class="statusIcon(list.status).color"
                    style="font-size: 20px"
                /></template>
                <template v-if="reportType === 'score'">
                  {{ list.score }}</template
                >
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // status, score
    reportType: {
      type: String,
      required: true,
    },
    itemLoops: {
      type: Array,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    statusIcon(status) {
      let state = {
        icon: 'minus-circle',
        color: '#595959',
      }

      switch (status) {
        case 'above':
          state = { icon: 'check-circle', color: 'text-success' }
          return state
        case 'bellow':
          state = { icon: 'check-circle', color: 'text-danger' }
          return state
        case 'not_graded':
          state = { icon: 'exclamation-circle', color: 'text-warning' }
          return state
        default:
          return state
      }
    },
  },
}
</script>

<style>
</style>
